/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


// tutorGrada-controller.js
angular.module('neo.home.tutorGrada')
  .controller('TutorGradaCtrl', TGCtrl);
  
    function TGCtrl(AclService,Comitetg, townHalls, currentSeason, $mdToast, $filter, $scope, $state) {
    var vm = this;
    vm.currentSeason = angular.copy(currentSeason);
     vm.sancionsshow=false;
    if( AclService.isAdmin()){
        townHalls.unshift({id:9999, name:'Tots', email:'', phoneNumber:0});
        vm.sancionsshow=true;
    }
    vm.townhalls = angular.copy(townHalls);
    vm.currentSeason.iniDate = moment(vm.currentSeason.iniDate).toDate();
    vm.currentSeason.endDate = moment(vm.currentSeason.endDate).toDate();

    //vm.iniDate = (angular.isUndefined($state.current.data.initDate)) ? vm.currentSeason.iniDate : $state.current.data.initDate;
    //vm.endDate = (angular.isUndefined($state.current.data.endDate)) ? vm.currentSeason.endDate : $state.current.data.endDate;
    var d = new Date();
    //d.setDate(d.getDate() - 7);
    vm.iniDate=d;
     var d2 = new Date();
    d2.setDate(d.getDate() + 7);
    vm.endDate=d2;
    
    vm.paginationLabel = {
      page: $filter('translate')('PAGE'),
      rowsPerPage: '',
      of: $filter('translate')('OF')
    };

    vm.matches = [];
    vm.matches_visit = [];
    vm.selected = [];
    vm.select = undefined;

    vm.openMenu = function ($mdMenu, event) {
      $mdMenu.open(event);
    };

    if (vm.townhalls.length === 1) {
      vm.selectedTownHall = vm.townhalls[0];
      $state.current.data.townHall = vm.selectedTownHall;
    } else {
      vm.selectedTownHall = ($state.current.data.townHall) ? $state.current.data.townHall : undefined;
    }
    vm.currentPage = 1;
    vm.matchesPerPage = 1500;
    vm.queryOrder = 'championship';
    vm.sort = 'championship';

    vm.applyFilters = applyFilters;
    vm.reorder = reorder;
    vm.getNewPage = getNewPage;

    function applyFilters() {
      vm.isFiltered = true;
      query(vm.selectedTownHall.id, vm.iniDate, vm.endDate, 1);
      
    }

    function reorder(order) {
      vm.sort = order;
      query(vm.selectedTownHall.id, vm.iniDate, vm.endDate, vm.currentPage, vm.matchesPerPage, vm.sort);
    }

    function getNewPage(page, limit) {
      vm.currentPage = page;
      vm.matchesPerPage = limit;
      query(vm.selectedTownHall.id, vm.iniDate, vm.endDate, vm.currentPage, vm.matchesPerPage, vm.sort);
    }

    function query(townHallId, iniDate, endDate, localvisitante) {
      var parameters = {
        townHall:townHallId.toString(),
        initDate: moment(iniDate).format('YYYY-MM-DD').toString(),
        endDate: moment(endDate).format('YYYY-MM-DD').toString(),
        localvisitant: localvisitante.toString() 
      };
      
      //Optional parameters
    
     
      Comitetg.get(parameters, function (data) {
         
         // _.remove(vm.matches);
          var isCC="";
          var isvT="";
          var resultat="";
          var isSus="";
          var isTJ="";
          var isObs="";
      
         if (true) {angular.forEach(data, function (match) {
          
            vm.matches.push({
              idPartido: match.idPartido,
              campeonato: match.campeonato,
              equipoLocal: match.equipoLocal,
              equipoVisitante: match.equipoVistante,//ojo falta i
              date: match.date,
              emailapp: match.emailapp,
              emailplayoff: match.emailplayoff,
              isOK: "NO",
              idequipo: match.idequipo,
              equipoafectado: match.equipoafectado
             
            });
          });
         
        }} 
        ,
        function (error) {
          var message = angular.isDefined(error.data.message) ? error.data.message : 'TOAST_ERROR';
          $mdToast.show(
            $mdToast.simple()
              .textContent($filter('translate')(message))
              .position('bottom left')
              .hideDelay(3000)
          );
        });
    }

    function findItemById(collection, itemId) {
      return _.find(collection, function (i) {
        return i.id === itemId;
      });
    }

    $scope.$watch('vm.iniDate', function (newDate) {
      $state.current.data.initDate = newDate;
    });

    $scope.$watch('vm.endDate', function (newDate) {
      $state.current.data.endDate = newDate;
    });

    $scope.$watch('vm.selectedTownHall', function (selectedTownHall) {
      $state.current.data.townHall = selectedTownHall;
    });
 $scope.exportToExcel= ((function() {
          var uri = 'data:application/vnd.ms-excel;base64,'
            , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
            , base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) }
            , format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) }
          return function(table, name) {
            table = document.getElementById(table);
            
            var ctx = {worksheet: name || 'Report', table: table.innerHTML};
            window.location.href = uri + base64(format(template, ctx));
          }
        })());
  $scope.sancions=((function(){
        var iniDate1=moment(vm.iniDate).format('YYYY-MM-DD');
        var endDate1= moment(vm.endDate).format('YYYY-MM-DD');
        var url = $state.href("home.sancions",{dataini: iniDate1,datafi:endDate1});
                  window.open(url,'_blank');
  })) ;     

$scope.loadExcel = function () {
  var input = document.getElementById('fileInput');
  var file = input.files[0]; // Obtener el archivo seleccionado

  if (file) {
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      var workbook = XLSX.read(data, { type: 'binary' });

      // Leer la primera hoja del archivo Excel
      var firstSheet = workbook.Sheets[workbook.SheetNames[0]];

      // Convertir la hoja a formato JSON
      var jsonData = XLSX.utils.sheet_to_json(firstSheet);
      
      // Crear un diccionario para relacionar Codi Equip con Email
      var equipoEmailMap = {};
      angular.forEach(jsonData, function (row) {
     
        if (row['Codi Equip'] && row['Email']) {
          equipoEmailMap[row['Codi Equip']] = row['Email'];
        }
      });

      // Recorrer los matches y verificar la condición
      angular.forEach(vm.matches, function (match) {
        var equipoId = match.idequipo;
        
        var emailFromExcel = equipoEmailMap[equipoId];
        match.emailplayoff = emailFromExcel;
        if (emailFromExcel && emailFromExcel === match.emailapp) {
          match.isOK = 'OK';
        } else {
          match.isOK = 'ERROR';
        }
      });

      $scope.$apply(); // Refresca la vista de Angular
    };

    reader.readAsBinaryString(file); // Leer el archivo como binario
  }
};

 
      $scope.verpartido=function(ident){
       
          var url = $state.href("home.resultats",{idPartit: ident});
                  window.open(url,'_blank');
     
      };
  }
  
  
